<template>
    <ShipDialog
        :showTab="showTab"
        width="500px"
        size="small"
        :visible.sync="visibleSync"
        title="宁波港码头修改"
        append-to-body
    >
        <div
            style="
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 20px;
                margin-top: 20px;
            "
        >
            <span>码头：</span>
            <el-select  v-model="wharfCode">
                <el-option
                    v-for="item in list"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                ></el-option>
            </el-select>
        </div>
        <DialogFooter
            @confirm="handleTuConfirm"
            @cancel="handleCancel"
            :loading="loading"
            :showTab="showTab"
            top="50"
        ></DialogFooter>
    </ShipDialog>
</template>
<script>
import ShipDialog from "@/components/layout/ShipDialog.vue";
import DialogFooter from "@/components/layout/DialogFooter.vue";
import { updateWharf } from "@/api/ship";
import {wharfCodeAndName} from "@/utils"
export default {
    name: "NBWharfDialog",
    props: ["showTab"],
    data() {
        return {
            visibleSync: false,
            id: 0,
            loading: false,
            wharfCode:"",
            list:[]
        };
    },
    components: {
        ShipDialog,
        DialogFooter,
    },
    methods: {
        init(item){
            this.visibleSync = true;
            this.id = item.id;
            const array=[]
            Object.entries(wharfCodeAndName).forEach(([key,value])=>{
                array.push({
                    value:key,
                    label:value
                }) 
            })
            this.list=array
            this.wharfCode=item.wharf
        },
        handleCancel() {
            this.visibleSync = false;
        },
        handleTuConfirm() {
            this.loading = true;
            updateWharf({
                id: this.id,
                wharfCode: this.wharfCode,
            })
                .then(() => {
                    this.$message.success("码头修改成功");
                    this.visibleSync = false;
                    this.$emit("confirm", {
                        id: this.id,
                        wharfCode: this.wharfCode,
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
</script>
<style lang="stylus" scoped>
.fcw-change-ship-switch{
    /deep/ .el-switch__label{
        
        span{
            font-size:20px !important;
        }
    }
    /deep/ .is-active{
        color:#b87100 !important;
    }
}
</style>