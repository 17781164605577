<!-- 详情弹窗提单信息 -->
<template>
    <div>
        <p class="info_title" @click="handleTitleClick">提单信息</p>
        <div class="billNo_step_div">
            <div
                style="display: flex"
                v-for="item in blonInfoList"
                :key="item.title"
            >
                <div v-if="item.type === 'dot'" class="dot_div">...</div>
                <div class="step_div" v-else>
                    <el-image
                        :src="
                            blonInfoState[item.imgDep]
                                ? icon_success
                                : icon_wait
                        "
                        class="stepIcon"
                    ></el-image>
                    <p class="p_no_margin">{{ item.title }}</p>
                    <template v-if="item.param">
                        <p class="p_time">
                            {{ splitDateAndTime(blonParam[item.param]).date }}
                        </p>
                        <p class="p_time">
                            {{ splitDateAndTime(blonParam[item.param]).time }}
                        </p>
                    </template>
                    <!-- 码头运抵 -->
                    <template
                        v-if="
                            ['SH'].includes(showTab) &&
                            boxInfo.ctnNum &&
                            item.imgDep === 'cpcodeState'
                        "
                    >
                        <div
                            class="p_time"
                            style="white-space: pre-wrap; text-align: center"
                        >
                            预配/进港/舱单：{{ showCtnNum }}
                        </div>
                        <div
                            :class="boxInfo.isError"
                            class="p_time"
                            style="white-space: pre-wrap; text-align: center"
                        >
                            {{ boxInfo.errorValue }}
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { splitDateAndTime, insertValueBetweenElements } from "@/utils";
import { BLON_INFO_LIST } from "@/utils/detail-content";
import Moment from "moment";
export default {
    name: "DetailContentBlon",
    props: {
        // 当前箱
        activePosition: {
            type: Number,
            default: 0,
        },
        // 当前tab
        showTab: {
            type: String,
        },
        boxInfo: {
            type: Object,
            default: () => {},
        },
        headers: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            icon_success: require("@/assets/img/light2.png"),
            icon_wait: require("@/assets/img/light.png"),
            // 提单的状态
            blonInfoState: {},
            // 提单的信息
            blonParam: {},
            splitDateAndTime,
        };
    },
    mounted() {
        this.init();
    },

    computed: {
        curBox() {
            const type =
                this.showTab === "NB"
                    ? "nppInGateEmptyVoList"
                    : "ctnEmptyVoList";
            return this.boxInfo[type]
                ? this.boxInfo[type][this.activePosition]
                : {};
        },
        blonInfoList() {
            // const list =
            //     this.showTab === "NB"
            //         ? BLON_INFO_LIST
            //         : BLON_INFO_LIST.filter((item) => item.show !== "NB");
            let list = BLON_INFO_LIST.filter((item) =>
                item.show.includes(this.showTab),
            );
            console.log(list, "list");
            return insertValueBetweenElements(list, { type: "dot" });
        },
        showCtnNum() {
            if (this.boxInfo.blnoInfo) {
                const { inGateCount, preInGateCount, nppCtnCount } =
                    this.boxInfo.blnoInfo;
                return `${preInGateCount ? preInGateCount + "箱" : "NO"}/${
                    inGateCount ? inGateCount + "箱" : "NO"
                }/${nppCtnCount ? nppCtnCount + "箱" : "NO"}`;
            } else {
                return this.boxInfo.ctnNum;
            }
        },
    },
    watch: {
        boxInfo: {
            handler(val) {
                this.init();
                this.preentryIsOk();
                this.costcoIsOk();
                this.arrivalTimeIsOk();
                this.cusmovIsOk();
                this.jgApplyIsOk();
                this.backTimeIsOK();
                this.inputTimeIsOK();
                this.firstPrintDatetimeIsOk();
                this.bkDatetimeFlagIsOk();
                this.emptyOutFlagIsOk();
                this.giNameFlagIsOk();
                this.vgmDatetimeFlagIsOk();
                this.manifestFlagIsOk();
                this.customPassFlagIsOk();
                this.lsDatetimeFlagIsOk();
                // this.arrivePortIsOk();
            },
            deep: true,
        },
        headers: {
            handler(val) {
                this.jgApplyIsOk();
                this.backTimeIsOK();
                this.inputTimeIsOK();
            },
            deep: true,
        },
    },

    methods: {
        handleTitleClick() {
            document.querySelector("#logistics-detail-top").scrollIntoView();
        },
        // 初始化
        init() {
            // 初始化前重置数据（避免本票单子没数据时显示的上一票单子数据）
            this.blonInfoState = {};
            this.blonParam = {};
            this.blonInfoState = {
                ...this.blonInfoList.map((item) => ({
                    [item.imgDep]: false,
                })),
            };
        },
        // 判断某个值是否为Y
        checkValueIsY(value) {
            return value === "Y";
        },
        //预配舱单
        preentryIsOk() {
            var box = this.boxInfo.manifest;
            // 上海青岛状态
            this.blonInfoState.preentryState = this.checkValueIsY(
                this.boxInfo.blnoInfo?.preFlag,
            );
            if (box) {
                // 宁波状态
                if (this.showTab === "NB") {
                    this.blonInfoState.preentryState = Boolean(box.saveTime);
                }
                this.blonParam.preentryTime =
                    box.saveTime && box.saveTime !== "无" ? box.saveTime : "";
            }
        },
        //判断是否已预录入
        costcoIsOk() {
            const box = this.curBox;
            if (box && box.ctn) {
                const costco_time = box.ctn.costcoTime;
                this.blonParam.costcoTime = costco_time;
                this.blonInfoState.costcoState = Boolean(costco_time);
            }
        },
        //判断是否已进场
        arrivalTimeIsOk() {
            const box = this.curBox;
            if (box && box.ctn) {
                var truck_time = ["NB", "XM"].includes(this.showTab)
                    ? box.ctn.inGateTime
                    : box.ctn.inPortTime;
                this.blonParam.arrivalTime = truck_time;

                this.blonInfoState.arrivalTimeState = Boolean(truck_time);
            }
        },
        //海关放行
        cusmovIsOk() {
            const box = this.curBox;
            if (box && box.npp) {
                const needCheckTypeList = [
                    "passFlag", //码头放行
                    "customFlag", // 海关放行
                    "ifcsumFlag", //清洁舱单
                    "sldFlag", //三联单
                    "cpcodeFlag", //码头运抵
                ];
                needCheckTypeList.forEach((item) => {
                    this.blonInfoState[item.replace("Flag", "State")] =
                        this.checkValueIsY(box.npp[item]);
                });
            } else if (this.showTab && this.showTab === "TJ") {
                // 海关放行
                this.blonInfoState.isCustomPass = this.checkValueIsY(
                    this.boxInfo.blnoInfo?.customFlag,
                );
            } else if (this.showTab && this.showTab !== "NB") {
                // 码头运抵
                this.blonInfoState.cpcodeState = this.checkValueIsY(
                    this.boxInfo.blnoInfo?.cpcodeFlag,
                );
                // 海关放行
                this.blonInfoState.isCustomPass =
                    this.curBox?.ctn?.customPassFlag === "1";
                // 码头放行
                this.blonInfoState.passState = this.checkValueIsY(
                    this.curBox?.ctn?.portPassFlag,
                );
            }
            if (box && box.ctn && ["NB", "XM"].includes(this.showTab)) {
                const { isCustomPass, customPassTime, loadTime, portPassTime } =
                    box.ctn;
                this.blonParam.customPassTime = customPassTime;
                // 海放
                this.blonInfoState.isCustomPass = isCustomPass;
                //装船出港(装船时间)
                this.blonInfoState.loadState = Boolean(loadTime);
                this.blonParam.loadTime = loadTime;
                if (this.showTab === "XM") {
                    // 码放信息
                    this.blonInfoState.passState = Boolean(portPassTime);
                    this.blonParam.receiveTime = portPassTime;
                }
            }
        },
        // 集港
        jgApplyIsOk() {
            const curCtn = this.headers;
            if (curCtn && curCtn.cargoTime) {
                this.blonInfoState.jgApplyState = Boolean(
                    curCtn.cargoTime.prop,
                );
                this.blonParam.jgApplyTime = curCtn.cargoTime.prop;
                // const jgApplyTime = box.ctn.jgApplyTime;
                // this.blonParam.jgApplyTime = jgApplyTime;
                // this.blonInfoState.jgApplyState = Boolean(jgApplyTime);
            }
        },
        // 返场
        backTimeIsOK() {
            const curCtn = this.headers;
            if (curCtn && curCtn.backTime) {
                this.blonInfoState.backState = Boolean(curCtn.backTime.prop);
                this.blonParam.backTime = curCtn.backTime.prop;
            }
        },
        // 提箱
        inputTimeIsOK() {
            const curCtn = this.headers;
            if (curCtn && curCtn.inputTime) {
                this.blonInfoState.inputState = Boolean(curCtn.inputTime.prop);
                this.blonParam.inputTime = curCtn.inputTime.prop;
            }
        },
        // 进港
        arrivePortIsOk() {
            if (this.showTab === "TJ") {
                if (this?.boxInfo?.blnoInfo) {
                    this.blonInfoState.portArrivalState = Boolean(
                        this.boxInfo.blnoInfo.inGateCount,
                    );
                }
            }
        },
        //首次打单时间
        firstPrintDatetimeIsOk() {
            if (this.showTab === "SZ" && this.boxInfo) {
                this.blonInfoState.firstPrintDatetimeState =
                    Boolean(this.boxInfo.firstPrintDatetime) ||
                    this.boxInfo.tcStatus==20;
            }
        },
        // 订舱
        bkDatetimeFlagIsOk() {
            if (this.showTab === "SZ" && this.boxInfo) {
                this.blonInfoState.bkDatetimeFlag = Boolean(
                    this.boxInfo.bkDatetime,
                )||this.boxInfo.tcStatus==10;
            }
        },
        // 提空
        emptyOutFlagIsOk() {
            if (this.showTab === "SZ" && this.boxInfo) {
                this.blonInfoState.emptyOutFlag = Boolean(
                    this.boxInfo.emptyOutTime,
                )||this.boxInfo.tcStatus==30;
            }
        },
        // 还重
        giNameFlagIsOk() {
            if (this.showTab === "SZ" && this.boxInfo) {
                this.blonInfoState.giNameFlag = Boolean(this.boxInfo.giName)||this.boxInfo.tcStatus==40;
            }
        },
        // VGM
        vgmDatetimeFlagIsOk() {
            if (this.showTab === "SZ" && this.boxInfo) {
                this.blonInfoState.vgmDatetimeFlag = Boolean(
                    this.boxInfo.vgmDatetime,
                )||this.boxInfo.tcStatus==50;
            }
        },
        // 舱单
        manifestFlagIsOk() {
            if (this.showTab === "SZ" && this.boxInfo) {
                this.blonInfoState.manifestFlag = Boolean(
                    this.boxInfo.manifestTime,
                )||this.boxInfo.tcStatus==60;
            }
        },
        // 海关放行
        customPassFlagIsOk() {
            if (this.showTab === "SZ" && this.boxInfo) {
                this.blonInfoState.customPassFlag = Boolean(
                    this.boxInfo.customPassTime,
                )||this.boxInfo.tcStatus==70;
            }
        },
        // 装船
        lsDatetimeFlagIsOk() {
            if (this.showTab === "SZ" && this.boxInfo) {
                this.blonInfoState.lsDatetimeFlag = Boolean(
                    this.boxInfo.lsDatetime,
                )||this.boxInfo.tcStatus==80;
            }
        },
    },
};
</script>
<style scoped>
.info_title {
    display: block;
    width: fit-content;
    font-weight: 700;
    font-size: 1.6rem;
    margin: 0 0 1rem;
    color: #409eff;
    cursor: pointer;
}

.info_title:before {
    content: "";
    height: 2rem;
    margin-top: 12px;
    margin-right: 1rem;
    border-left: 2px solid #409eff;
}

.billNo_step_div {
    margin-bottom: 3rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.step_div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.stepIcon {
    width: 6rem;
    height: 6rem;
    background-size: 100% 100%;
    user-select: none;
}

.dot_div {
    height: 6rem;
    line-height: 3rem;
    font-size: 6rem;
    color: #ccc;
    user-select: none;
}
.p_time {
    font-weight: 700;
}

p {
    margin: 0;
}
.p_no_margin {
    margin: 1rem 0;
    font-weight: 700;
    font-size: 1.6rem;
}
</style>
