var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box_div back_div"},[_c('p',{staticClass:"info_title",on:{"click":_vm.handleTitleClick}},[_vm._v(" 箱动态【"+_vm._s(_vm.boatInfo.vesselEname ? _vm.boatInfo.vesselEname : _vm.boxInfo.vesselsName)+" / "+_vm._s(_vm.boatInfo.voyage ? _vm.boatInfo.voyage : _vm.boxInfo.voyage)+"】 "),(_vm.showTab==='NB')?_c('span',[_vm._v("【堆存天数："+_vm._s(_vm.storageDays)+" 天】")]):_vm._e()]),_c('div',[_c('el-descriptions',{staticClass:"margin-top",attrs:{"column":3,"border":""}},_vm._l((_vm.loopKey),function(key){return _c('el-descriptions-item',{key:_vm.headers[key].label,attrs:{"content-class-name":[
                    {
                        pink_cell:
                            (_vm.headers[key].label == '货重' &&
                                _vm.errorFlag == 1) ||
                            (_vm.headers[key].label == '累计总数' &&
                                _vm.errorFlag == 1),
                    },
                ]}},[_c('template',{slot:"label"},[_vm._v(" "+_vm._s(_vm.headers[key].label)+" ")]),_vm._v(" "+_vm._s(_vm.headers[key].prop)+" ")],2)}),1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }