<!-- 详情弹窗船期信息 -->
<template>
    <div v-if="boatInfo" id="logistics-detail-boatInfo">
        <p class="info_title" @click="handleTitleClick">
            船期信息【{{
                boatInfo.vesselEname
                    ? boatInfo.vesselEname
                    : boxInfo.vesselsName
            }}&ensp;/&ensp;{{
                boatInfo.voyage ? boatInfo.voyage : boxInfo.voyage
            }}
            】
            <a @click="changeVoyage()" v-if="canUpdateShip&&showTab!=='SZ'">
                &emsp;修改船名航次
            </a>
        </p>

        <div class="billNo_info_table_div">
            <el-table
                border
                :data="shipInfo"
                style="width: 100%"
                :header-cell-style="{
                    background: '#eef1f6',
                    color: '#606266',
                }"
            >
                <el-table-column>
                    <template slot="header">
                        <div class="flex_div">
                            <p class="p_no_margin2">
                                船舶：{{
                                    boatInfo.vesselEname
                                        ? boatInfo.vesselEname
                                        : boxInfo.vesselsName
                                }}
                            </p>
                            <p
                                class="ship_font"
                                style="color: blue; cursor: pointer"
                                @click="goShipMap(boatInfo)"
                            >
                                船舶定位
                            </p>
                        </div>
                    </template>

                    <el-table-column
                        label="预计抵港时间"
                        v-if="!['QD', 'TJ','SZ'].includes(showTab)"
                    >
                        <template slot-scope="scope">
                            {{
                                scope.row.etaArrivalTime,
                            }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        v-if="!['QD', 'TJ','SZ'].includes(showTab)"
                        prop="etaDepartureTime"
                        label="预计离港时间"
                    >
                        <template slot-scope="scope">
                            {{
                                scope.row.etaDepartureTime,

                            }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="ctnApplyBeginTime"
                        label="收箱开始时间"
                        v-if="showTab === 'QD'"
                    ></el-table-column>
                    <el-table-column
                        prop="ctnApplyEndTime"
                        label="收箱截止时间"
                        v-if="showTab === 'QD'"
                    ></el-table-column>
                    <el-table-column prop="bkDatetime" label="订舱时间" v-if="['SZ'].includes(showTab)"></el-table-column>
                    <el-table-column prop="firstPrintDatetime" label="打单时间" v-if="['SZ'].includes(showTab)"></el-table-column>
                    <el-table-column prop="emptyOutTime" label="提空时间" v-if="['SZ'].includes(showTab)"></el-table-column>
                </el-table-column>
                <el-table-column>
                    <template slot="header">
                        <div class="flex_div">
                            <p class="p_no_margin2">
                                出口航次：{{
                                    boatInfo.voyage
                                        ? boatInfo.voyage
                                        : boxInfo.voyage
                                }}
                            </p>
                        </div>
                    </template>
                    <el-table-column
                        prop="ctnApplyBeginTime"
                        label="进箱开始时间"
                        v-if="!['TJ', 'QD','SZ'].includes(showTab)"
                    ></el-table-column>
                    <el-table-column
                        prop="ctnApplyEndTime"
                        label="进箱截止时间"
                        v-if="!['TJ', 'QD','SZ'].includes(showTab)"
                    ></el-table-column>
                    <el-table-column
                        prop="jgApplyBeginTime"
                        label="集港开始(开港)时间"
                        v-if="['QD', 'TJ'].includes(showTab)"
                    ></el-table-column>
                    <el-table-column
                        prop="jgApplyEndTime"
                        label="集港结束(截港)时间"
                        v-if="['QD', 'TJ'].includes(showTab)"
                    ></el-table-column>
                    <el-table-column
                        prop="portCloseTime"
                        label="截单时间"
                        v-if="['NB', 'XM'].includes(showTab)"
                    >
                        <template slot-scope="scope">
                            {{
                                scope.row.portCloseTime,

                            }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="giDatetime" label="还重时间" v-if="['SZ'].includes(showTab)"></el-table-column>
                    <el-table-column prop="manifestTime" label="申报时间" v-if="['SZ'].includes(showTab)"></el-table-column>
                </el-table-column>
                <el-table-column>
                    <template slot="header">
                        <div class="flex_div">
                            <p class="p_no_margin2">
                                停靠码头：{{
                                    showTab === "NB"
                                        ? wharfCodeToStr(boatInfo.wharf)
                                        : boatInfo.wharf
                                }}
                                <a
                                    @click="changeWharf"
                                    v-if="
                                        showTab === 'NB' 
                                    "
                                >
                                    &emsp;修改码头
                                </a>
                            </p>
                        </div>
                    </template>
                    <el-table-column
                        label="预计抵港时间"
                        v-if="showTab === 'QD'"
                    >
                        <template slot-scope="scope">
                            {{
                                scope.row.etaArrivalTime,
                            }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="etaDepartureTime"
                        label="预计离港时间"
                        v-if="showTab === 'QD'"
                    >
                        <template slot-scope="scope">
                            {{
                                scope.row.etaDepartureTime,

                            }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        v-if="!['TJ','SZ'].includes(showTab)"
                        prop="arrivalTime"
                        label="实际靠港时间"
                    ></el-table-column>
                    <el-table-column
                        v-if="!['TJ','SZ'].includes(showTab)"
                        prop="departureTime"
                        label="实际离港时间"
                    ></el-table-column>
                   
                    <el-table-column prop="customPassTime" label="海关放行" v-if="['SZ'].includes(showTab)"></el-table-column>
                    <el-table-column prop="lsDatetime" label="装船时间" v-if="['SZ'].includes(showTab)">

                    </el-table-column>
                </el-table-column>
            </el-table>
        </div>
        <voyage-change-dialog
            v-if="voyageDialogVisible"
            ref="VoyageChangeDialog"
            @refreshDataList="handleRefresh"
            :showTab="showTab"
        ></voyage-change-dialog>
        <!-- 宁波修改码头 -->
        <NBWharfDialog
            v-if="wharfDialogVisible"
            ref="WharfDialog"
            @confirm="handleChangeWharf"
        />
    </div>
</template>
<script>
import VoyageChangeDialog from "../VoyageChangeDialog";
import { wharfCodeToStr } from "@/utils";
import NBWharfDialog from "@/views/Logistics/components/NBWharfDialog.vue";
export default {
    name: "DetailContentShip",
    props: {
        boatInfo: {
            type: Object,
            default: () => {},
        },
        shipInfo: {
            type: Array,
            default: () => [],
        },
        boxInfo: {
            type: Object,
            default: () => {},
        },
        showTab: {
            type: String,
        },
    },
    data() {
        return {
            voyageDialogVisible: false,
            wharfCodeToStr,
            wharfDialogVisible: false,
        };
    },
    computed: {
        canUpdateShip() {
            return !Boolean(this.boxInfo?.blnoInfo?.inGateCount);
        },
    },
    components: {
        VoyageChangeDialog,
        NBWharfDialog,
    },
    methods: {
        changeWharf() {
            console.log(this,'this')
            this.wharfDialogVisible = true;
            this.$nextTick(() => {
                this.$refs.WharfDialog.init(this.boatInfo);
            });
        },
        handleTitleClick() {
            document.querySelector("#logistics-detail-top").scrollIntoView();
        },
        goShipMap(shipInfo) {
            const newpage = this.$router.resolve({
                path: "/shipmap",
                query: {
                    id: shipInfo.id,
                    blno: shipInfo.blno,
                    vesselEname: shipInfo.vesselEname,
                    voyage: shipInfo.voyage,
                },
                params: { item: shipInfo },
            });
            window.open(newpage.href, "_blank");
        },
        //修改船名航次
        changeVoyage() {
            this.voyageDialogVisible = true;
            var dataList = [];
            dataList.push(this.boxInfo);
            this.$nextTick(() => {
                this.$refs.VoyageChangeDialog.init(dataList);
            });
        },
        //修改船名航次后刷新参数和页面
        handleRefresh() {
            this.$emit("confirm");
        },
        handleChangeWharf(params) {
            this.$emit("confirm");
            this.boatInfo.wharf = params.wharfCode;
        },
    },
};
</script>
<style scoped>
.info_title {
    display: block;
    width: fit-content;
    font-weight: 700;
    font-size: 1.6rem;
    margin: 0 0 1rem;
    color: #409eff;
    cursor: pointer;
}

.info_title:before {
    content: "";
    height: 2rem;
    margin-top: 12px;
    margin-right: 1rem;
    border-left: 2px solid #409eff;
}

.p_no_margin2 {
    margin: 0;
    font-weight: 700;
}

.flex_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.ship_font {
    display: flex;
    font-size: 10px;
}
a {
    cursor: pointer;
    font-weight: 400;
    font-size: 1.4rem;
    color: rgb(85, 114, 253);
}
</style>
