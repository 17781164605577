var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"info_title",on:{"click":_vm.handleTitleClick}},[_vm._v("提单信息")]),_c('div',{staticClass:"billNo_step_div"},_vm._l((_vm.blonInfoList),function(item){return _c('div',{key:item.title,staticStyle:{"display":"flex"}},[(item.type === 'dot')?_c('div',{staticClass:"dot_div"},[_vm._v("...")]):_c('div',{staticClass:"step_div"},[_c('el-image',{staticClass:"stepIcon",attrs:{"src":_vm.blonInfoState[item.imgDep]
                            ? _vm.icon_success
                            : _vm.icon_wait}}),_c('p',{staticClass:"p_no_margin"},[_vm._v(_vm._s(item.title))]),(item.param)?[_c('p',{staticClass:"p_time"},[_vm._v(" "+_vm._s(_vm.splitDateAndTime(_vm.blonParam[item.param]).date)+" ")]),_c('p',{staticClass:"p_time"},[_vm._v(" "+_vm._s(_vm.splitDateAndTime(_vm.blonParam[item.param]).time)+" ")])]:_vm._e(),(
                        ['SH'].includes(_vm.showTab) &&
                        _vm.boxInfo.ctnNum &&
                        item.imgDep === 'cpcodeState'
                    )?[_c('div',{staticClass:"p_time",staticStyle:{"white-space":"pre-wrap","text-align":"center"}},[_vm._v(" 预配/进港/舱单："+_vm._s(_vm.showCtnNum)+" ")]),_c('div',{staticClass:"p_time",class:_vm.boxInfo.isError,staticStyle:{"white-space":"pre-wrap","text-align":"center"}},[_vm._v(" "+_vm._s(_vm.boxInfo.errorValue)+" ")])]:_vm._e()],2)])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }