var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detail-customs-release box_div back_div",attrs:{"id":"logistics-detail-release"}},[_c('p',{staticClass:"info_title2"},[_vm._v("海关放行信息")]),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"border":"","data":_vm.checkInfoList,"header-cell-style":{
                background: '#eef1f6',
                color: '#606266',
            }}},[_c('el-table-column',{attrs:{"prop":"data_1","label":"报关单号"}}),([ 'NB','XM'].includes(_vm.showTab))?_c('el-table-column',{attrs:{"prop":"data_2","label":"海放时间"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('p',[_vm._v(_vm._s(_vm.splitDateAndTime(scope.row.data_2).date))]),_c('p',[_vm._v(_vm._s(_vm.splitDateAndTime(scope.row.data_2).time))])]}}],null,false,2785006901)}):_vm._e(),(_vm.showTab === 'NB')?_c('el-table-column',{attrs:{"prop":"data_5","label":"清洁舱单"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-image',{staticClass:"passIcon",attrs:{"src":scope.row.data_5 ? _vm.icon_green : _vm.icon_red}})]}}],null,false,1547694373)}):_vm._e(),(_vm.showTab === 'NB')?_c('el-table-column',{attrs:{"prop":"data_9","label":"码头运抵"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-image',{staticClass:"passIcon",attrs:{"src":scope.row.data_9 ? _vm.icon_green : _vm.icon_red}})]}}],null,false,995604521)}):_vm._e(),_c('el-table-column',{attrs:{"label":"海放状态"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-image',{staticClass:"passIcon",attrs:{"src":scope.row.data_3 ? _vm.icon_green : _vm.icon_red}})]}}])}),(_vm.showTab === 'SH')?_c('el-table-column',{attrs:{"prop":"data_12","label":"海放反馈"}}):_vm._e(),(_vm.showTab === 'NB')?_c('el-table-column',{attrs:{"prop":"data_4","label":"三联单"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-image',{staticClass:"passIcon",attrs:{"src":scope.row.data_4 ? _vm.icon_green : _vm.icon_red}})]}}],null,false,79771428)}):_vm._e(),_c('el-table-column',{attrs:{"prop":"data_10","label":"码头放行"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(_vm.showTab!=='XM')?_c('p',{class:[
                            { p_green: scope.row.data_10 === 'Y' },
                            { p_red: scope.row.data_10 === 'N' },
                        ]},[_vm._v(" "+_vm._s(_vm.passFlag2String( scope.row.data_10, _vm.showTab === "SH", ))+" ")]):(_vm.showTab==='XM')?_c('p',{class:[
                            { p_green: scope.row.data_10 == '1' },
                            { p_red: scope.row.data_10 == '0' },
                        ]},[_vm._v(" "+_vm._s(_vm.passFlag2StringXM( scope.row.data_10, ))+" ")]):_vm._e()]}}])}),(['NB','XM'].includes(_vm.showTab))?_c('el-table-column',{attrs:{"prop":"data_11","label":"码头反馈"}}):_vm._e(),(_vm.showTab === 'NB')?_c('el-table-column',{attrs:{"prop":"data_6","label":"海关查验状态"}}):_vm._e(),(_vm.showTab === 'NB')?_c('el-table-column',{attrs:{"prop":"data_7","label":"海关操作时间"}}):_vm._e(),(_vm.showTab === 'NB')?_c('el-table-column',{attrs:{"prop":"data_8","label":"986查验（机检）"}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }