import {
    ctnDynamicHeaderQD,
    ctnDynamicHeaderSH,
    ctnDynamicHeader,
    ctnDynamicHeaderTJ,
    ctnDynamicHeaderXM,
    ctnDynamicHeaderSZ,
} from "@/utils/dataList";

/**
 * @description 详情显示的字段，根据不同tab来区分
 * @param  tab NB SH QD
 * @param type 1. 箱展示
 */
export const detailContentShow = ({ tab, type }) => {
    switch (tab) {
        case "NB":
            switch (type) {
                case 1:
                    return ctnDynamicHeader;
            }
        case "SH":
            switch (type) {
                case 1:
                    return ctnDynamicHeaderSH;
            }
        case "QD":
            switch (type) {
                case 1:
                    return ctnDynamicHeaderQD;
            }
        case 'TJ':
            switch (type) {
                case 1:
                    return ctnDynamicHeaderTJ;
            }
        case 'XM':
            switch (type) {
                case 1:
                    return ctnDynamicHeaderXM;
            }
        case 'SZ':
            switch (type) {
                case 1:
                    return ctnDynamicHeaderSZ;
            }
    }
};
